<template>
	<div style="background: #f9f9fb;height: 100%;">
		<div style="padding: 10px 20px;background: #fff;position: relative;height: 60px;display: flex;align-items: center;border-bottom: 1px solid #f3f3f3;">
			<div style="width: 100%;">头像</div>
			
			<div style="position: absolute;right: 20px;top:10px;">
				<el-image style="width: 40px;height: 40px;border-radius: 10px;" v-if="avatarurl!==null" :src="avatarurl"></el-image>
				<el-image style="width: 40px;height: 40px;" v-else :src="require('@/assets/img/head.png')"></el-image>
			</div>
		</div>
		<div style="padding: 10px 20px;background: #fff;position: relative;display: flex;align-items: center;border-bottom: 1px solid #f3f3f3;">
			<div style="width: 100%;">名字</div>
			
			<div style="position: absolute;right: 20px;top:10px;">
				<div>{{userInfo.nickname}}</div>
			</div>
		</div>
		
		<div style="padding: 10px 20px;background: #fff;position: relative;display: flex;align-items: center;border-bottom: 1px solid #f3f3f3;">
			<div style="width: 100%;">手机号</div>
			
			<div style="position: absolute;right: 20px;top:10px;">
				<div>{{userInfo.contacttel}}</div>
			</div>
		</div>
		
		<div style="padding: 10px 20px;background: #fff;position: relative;display: flex;align-items: center;border-bottom: 1px solid #f3f3f3;">
			<div style="width: 100%;">积分</div>
			
			<div style="position: absolute;right: 20px;top:10px;">
				<div>{{userInfo.userscore}}</div>
			</div>
		</div>
		
		<!-- <div>{{userInfo}}</div> -->
		
		<applet-bottom></applet-bottom>
	</div>
</template>

<script>
	import {
		getUser,
	} from '@/api/userInfo/index'
	import appletBottom from '@/components/bottom/appletIndex.vue';
	export default {
		components: {
			appletBottom
		},
		data(){
			return {
				userInfo:null,
				img:process.env.VUE_APP_OSS_URL + '/home/personal.jpg',
				avatarurl:null,
			}
		},
		
		created() {
			this.getUser()
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			//--------------获取用户信息数据----------------------
			getUser: function() {
				var userInfo = JSON.parse(sessionStorage.getItem("getUserInfo"))
				let data = {
					id: userInfo.uid
				}
				getUser(data).then(res => {
					this.userInfo = res.data
					this.avatarurl = res.data.avatarurl
				})
			},
		}
	}
</script>

<style lang="less" scoped>
</style>